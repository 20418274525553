import { RootState } from '@connectors/reducers';
import DeviceTypes from '@enums/DeviceTypes';
import { createSelector } from 'reselect';
import {
  getObject,
  getParentObject,
  getObjectCountByNodeName,
  initialState,
  getAllColorsFromString,
} from './reducer';
import memoize from 'proxy-memoize';
import uniq from 'lodash/uniq';
import isEqual from 'lodash/isEqual';
import CustomizeSidebarTypes from '@enums/CustomizeSidebarTypes';
import { Attributes, Content, Node } from './campaign';
import { getAllFonts } from '@utils/builder';
import { NodeType } from '@model/types';
import { getReadonlyStatus } from '@utils/campaign';
import StepTypes from '@enums/StepTypes';

const selectBuilder = (state: RootState) => state.builder || initialState;

const makeSelectIsValidOrjLayout = createSelector(
  selectBuilder,
  (builderState) => builderState?.orjData?.layout?.contents,
);

const makeSelectCampaignFetchStatus = createSelector(
  selectBuilder,
  (builderState) => builderState.status,
);

const makeSelectCampaignTargets = createSelector(
  selectBuilder,
  (builderState) => builderState.data?.targets,
);

const makeSelectCampaignTargetsCopy = createSelector(
  selectBuilder,
  (builderState) => builderState.targetsCopyData,
);

const makeSelectTargetsCopySegmentId = createSelector(
  selectBuilder,
  (builderState) => builderState.data?.targets?.segmentId,
);

const makeSelectCampaignLayout = createSelector(
  selectBuilder,
  (builderState) => builderState.data?.layout,
);

const makeSelectOrjCampaignLayout = createSelector(
  selectBuilder,
  (builderState) => builderState?.orjData?.layout,
);

const makeSelectIsReadonlyCampaign = createSelector(
  selectBuilder,
  (builderState) => {
    if (!builderState.data?.createdAt) return true;

    const isReadonly = getReadonlyStatus(builderState.data?.createdAt);
    return isReadonly;
  },
);

const makeSelectOrjActiveDeviceAllContents = createSelector(
  selectBuilder,
  (builderState) => {
    if (
      !builderState.data ||
      !builderState.currentDeviceType ||
      !builderState.currentPageId
    )
      return null;
    return builderState.data.layout?.contents.map((page: Content) =>
      Object({
        pageId: page.id as string,
        pageTitle: page.title as string,
        content: page.content[builderState?.currentDeviceType as DeviceTypes],
        appearance: page.appearance,
      }),
    );
  },
);
const makeSelectCampaignId = createSelector(
  selectBuilder,
  (builderState) => builderState.data?.id,
);

const makeSelectActiveContent = createSelector(
  selectBuilder,
  (builderState) => {
    if (
      !builderState.data ||
      !builderState.currentDeviceType ||
      !builderState.currentPageId
    )
      return null;
    const pageIndex = builderState.data.layout?.contents
      .map((x) => x.id)
      .indexOf(builderState.currentPageId) as number;
    if (pageIndex === -1) return null;
    return builderState.data.layout?.contents[pageIndex].content[
      builderState.currentDeviceType
    ];
  },
);

type nodeType = {
  id: string;
  nodeName: NodeType;
  attributes: Attributes;
  childNodes: nodeType[];
};

const makeSelectActiveContentElementByNodeName = (
  nodeName: string,
  options?: {
    getLast?: boolean;
  },
) =>
  memoize((state: RootState) => {
    if (!(state.builder.data && state.builder.data.layout)) return null;
    const { currentPageId, currentDeviceType } = state.builder;
    if (!currentDeviceType || !currentPageId) return null;

    const currentNode = state.builder.data.layout.contents.find(
      (x) => x.id === currentPageId,
    )?.content[currentDeviceType];

    if (!currentNode?.childNodes) return null;
    const parentElementChildNodes = currentNode.childNodes;

    const parentNodes = (parentElementChildNodes as nodeType[]).filter(
      (node) => node.nodeName === nodeName,
    );

    const isOneColumnPopup =
      (parentElementChildNodes as nodeType[]).filter(
        (node) => node.nodeName === 'block',
      ).length === 0;

    if (nodeName === 'block' && isOneColumnPopup) {
      return currentNode;
    }

    if (parentNodes.length > 0) {
      const parentNode = options?.getLast
        ? parentNodes[parentNodes.length - 1]
        : parentNodes[0];
      if (parentNode) return parentNode;
    }

    let currentNodeChildrenNodes = isOneColumnPopup
      ? (currentNode.childNodes as nodeType[])
      : (currentNode.childNodes as nodeType[]).flatMap(
          (node) => node.childNodes,
        );

    const elementNode = currentNodeChildrenNodes.find(
      (node) => node.nodeName === nodeName,
    );
    return elementNode;
  });

const makeSelectActiveContentType = createSelector(
  selectBuilder,
  (builderState) => {
    if (
      !builderState.data ||
      !builderState.currentDeviceType ||
      !builderState.currentPageId
    )
      return null;
    const pageIndex = builderState.data.layout?.contents
      .map((x) => x.id)
      .indexOf(builderState.currentPageId) as number;
    if (pageIndex === -1) return null;
    return builderState.data.layout?.contents[pageIndex].type;
  },
);

const makeSelectCampaignIntegration = createSelector(
  selectBuilder,
  (builderState) => {
    if (!builderState?.orjData) return null;
    return {
      activeCampaignCampaigns:
        builderState.orjData.integrationActiveCampaignCampaigns,
      mailchimpCampaigns: builderState.orjData.integrationMailchimpCampaigns,
      hubSpotCampaigns: builderState.orjData.integrationHubSpotCampaigns,
      klaviyoCampaigns: builderState.orjData.integrationKlaviyoCampaigns,
      emailOctopusCampaigns:
        builderState.orjData.integrationEmailOctopusCampaigns,
      mailerliteCampaigns: builderState.orjData.integrationMailerliteCampaigns,
      dripCampaigns: builderState.orjData.integrationDripCampaigns,
      customerIoCampaigns: builderState.orjData.integrationCustomerIoCampaigns,
      sendgridCampaigns: builderState.orjData.integrationSendgridCampaigns,
      sendinBlueCampaigns: builderState.orjData.integrationSendinBlueCampaigns,
      webhooks: builderState.orjData.integrationWebhooks,
    };
  },
);

const makeSelectCampaignDetail = createSelector(
  [
    (state: RootState) => state.builder.data?.id,
    (state: RootState) => state.builder.data?.name,
    (state: RootState) => state.builder.data?.domains,
    (state: RootState) => state.builder.data?.publishVersion,
    (state: RootState) => state.builder.data?.settings,
    (state: RootState) => state.builder.data?.status,
    (state: RootState) => state.builder?.data?.publishLoadingStatus,
    (state: RootState) => state.builder?.data?.variantInfo,
  ],
  (
    id,
    name,
    domains,
    publishVersion,
    settings,
    status,
    publishLoadingStatus,
    variantInfo,
  ) => {
    return {
      id,
      name,
      domains,
      publishVersion,
      settings,
      status,
      publishLoadingStatus,
      variantInfo,
    };
  },
);

const makeSelectPublishVersion = createSelector(
  [(state: RootState) => state.builder.orjData?.publishVersion],
  (publishVersion) => {
    return publishVersion;
  },
);
const makeSelectOrjCampaignDetail = createSelector(
  [
    (state: RootState) => state.builder?.orjData?.id,
    (state: RootState) => state.builder?.orjData?.name,
    (state: RootState) => state.builder?.orjData?.domains,
    (state: RootState) => state.builder?.orjData?.publishVersion,
    (state: RootState) => state.builder?.orjData?.settings,
    (state: RootState) => state.builder?.orjData?.status,
    (state: RootState) => state.builder?.orjData?.publishLoadingStatus,
    (state: RootState) => state.builder?.orjData?.variantInfo,
  ],
  (
    id,
    name,
    domains,
    publishVersion,
    settings,
    status,
    publishLoadingStatus,
    variantInfo,
  ) => {
    return {
      id,
      name,
      domains,
      publishVersion,
      settings,
      status,
      publishLoadingStatus,
      variantInfo,
    };
  },
);

const makeSelectCampaignSettings = createSelector(
  selectBuilder,
  (builderState) => {
    if (!builderState.data?.settings) return null;
    return builderState.data?.settings;
  },
);

const makeSelectCampaignAutoResponder = createSelector(
  [
    (state: RootState) => state.builder?.orjData?.autoResponderMailBody,
    (state: RootState) => state.builder?.orjData?.autoResponderReplyTo,
    (state: RootState) => state.builder?.orjData?.autoResponderSenderName,
    (state: RootState) => state.builder?.orjData?.autoResponderStatus,
    (state: RootState) => state.builder?.orjData?.autoResponderSubject,
    (state: RootState) => state.builder.status,
  ],
  (
    autoResponderMailBody,
    autoResponderReplyTo,
    autoResponderSenderName,
    autoResponderStatus,
    autoResponderSubject,
    status,
  ) => {
    return {
      autoResponderMailBody,
      autoResponderReplyTo,
      autoResponderSenderName,
      autoResponderStatus,
      autoResponderSubject,
      status,
    };
  },
);

const makeSelectCampaignAutoResponderStatus = createSelector(
  (state: RootState) => state.builder?.orjData?.autoResponderStatus,
  (autoResponderStatus) => {
    return autoResponderStatus;
  },
);

const makeSelectCampaignCurrentDeviceType = createSelector(
  selectBuilder,
  (builderState) => {
    if (!builderState.currentDeviceType) return DeviceTypes.desktop;
    return builderState.currentDeviceType;
  },
);

const makeSelectCampaignCurrentPageId = createSelector(
  selectBuilder,
  (builderState) => {
    if (!builderState.currentPageId) return null;
    return builderState.currentPageId;
  },
);

const makeSelectCampaignCurrentPageTemplateImage = createSelector(
  selectBuilder,
  (builderState) => {
    if (!builderState.currentPageId) return null;
    const currentPage = builderState.data?.layout?.contents.find(
      (x) => x.id === builderState.currentPageId,
    );
    return currentPage?.template.imageUrl;
  },
);

// We can only choose 1 template, so we can get template ID from any page except teaser
const makeSelectCampaignCurrentPageTemplateId = createSelector(
  selectBuilder,
  (builderState) => {
    if (
      builderState.data?.layout?.contents?.length &&
      builderState.data?.layout?.contents?.length < 2
    )
      return null;
    return builderState.data?.layout?.contents?.[1].template.id;
  },
);

const makeSelectCampaignCurrentPage = createSelector(
  selectBuilder,
  (builderState) => {
    if (!builderState.currentPageId) return null;
    const currentPage = builderState.data?.layout?.contents.find(
      (x) => x.id === builderState.currentPageId,
    );
    return currentPage;
  },
);

const makeSelectCampaignCurrentOrjPage = createSelector(
  selectBuilder,
  (builderState) => {
    if (!builderState.currentPageId) return null;
    const currentPage = builderState.orjData?.layout?.contents.find(
      (x) => x.id === builderState.currentPageId,
    );
    return currentPage;
  },
);

const makeSelectCampaignCurrentPageAppearance = createSelector(
  selectBuilder,
  (builderState) => {
    if (!builderState.currentPageId) return null;
    const currentPage = builderState.data?.layout?.contents.find(
      (x) => x.id === builderState.currentPageId,
    );
    const appearance = currentPage?.appearance;
    return appearance;
  },
);

const makeSelectFullyCommonAppearanceUsedPages = createSelector(
  selectBuilder,
  (builderState) => {
    if (!builderState.currentPageId) return null;

    const isCommonAppearanceUsedPage = (page: Content) => {
      const isInlineFloatingPage =
        page.type === StepTypes.FLOATING && page.isInline;

      const isPageTeaser = page.type === StepTypes.TEASER;

      return !isInlineFloatingPage && !isPageTeaser;
    };

    const commonAppearanceUsedPages =
      builderState.data?.layout?.contents.filter((page) =>
        isCommonAppearanceUsedPage(page),
      );

    return commonAppearanceUsedPages;
  },
);

const makeSelectCampaignPreviousPageInfo = memoize(
  createSelector(selectBuilder, (builderState) => {
    if (!builderState.currentPageId) return null;
    const currentPageIndex = builderState.data?.layout?.contents.findIndex(
      (x) => x.id === builderState.currentPageId,
    );
    const previousPage =
      builderState.data?.layout?.contents?.[currentPageIndex! - 1];
    if (previousPage !== undefined)
      return {
        id: previousPage.id,
        title: previousPage.title,
        index: currentPageIndex! - 1,
        type: previousPage.type,
      };
    return undefined;
  }),
);

const makeSelectCampaignNextPageInfo = memoize(
  createSelector(selectBuilder, (builderState) => {
    if (!builderState.currentPageId) return null;
    const currentPageIndex = builderState.data?.layout?.contents.findIndex(
      (x) => x.id === builderState.currentPageId,
    );
    const nextPage =
      builderState.data?.layout?.contents?.[currentPageIndex! + 1];
    if (nextPage !== undefined)
      return {
        id: nextPage.id,
        title: nextPage.title,
        index: currentPageIndex! + 1,
        type: nextPage.type,
      };
    return undefined;
  }),
);

const makeSelectCampaignNextPageData = memoize(
  createSelector(selectBuilder, (builderState) => {
    if (!builderState.currentPageId) return null;
    const currentPageIndex = builderState.data?.layout?.contents.findIndex(
      (x) => x.id === builderState.currentPageId,
    );
    const nextPage =
      builderState.data?.layout?.contents?.[currentPageIndex! + 1];
    return nextPage;
  }),
);

const makeSelectCampaignVisitorDevice = createSelector(
  selectBuilder,
  (builderState) => {
    if (!builderState.data?.targets?.visitorDevice) return null;
    return builderState.data?.targets?.visitorDevice;
  },
);

const makeSelectPreview = createSelector(
  selectBuilder,
  (builderState) => builderState.preview,
);

const makeSelectCanvasDragDropStatus = createSelector(
  selectBuilder,
  (builderState) => builderState.isCanvasDragDropActive,
);

const makeSelectCampaignElement = ({
  id,
  pageId,
  deviceType,
}: {
  id: string;
  pageId?: string;
  deviceType?: DeviceTypes;
}) => {
  return createSelector(selectBuilder, (builderState) => {
    if (!(builderState.data && builderState.data.layout)) return null;
    if (!deviceType) deviceType = builderState.currentDeviceType;
    if (!pageId) pageId = builderState.currentPageId;
    if (!pageId || !deviceType) return;
    const currentContent = builderState.data.layout.contents.find(
      (x) => x.id === pageId,
    );
    if (!currentContent?.content) return null;
    return getObject(currentContent.content[deviceType], id);
  });
};

const makeSelectCampaignElementParent = ({
  id,
  pageId,
  deviceType,
}: {
  id: string;
  pageId?: string;
  deviceType?: DeviceTypes;
}) => {
  return createSelector(selectBuilder, (builderState) => {
    if (!(builderState.data && builderState.data.layout)) return null;
    if (!deviceType) deviceType = builderState.currentDeviceType;
    if (!pageId) pageId = builderState.currentPageId;
    if (!pageId || !deviceType) return;

    const currentContent = builderState.data.layout.contents.find(
      (x) => x.id === pageId,
    );
    if (!currentContent?.content) return null;
    return getParentObject(currentContent.content[deviceType], id);
  });
};

const makeSelectElementVisibleInDevice = ({
  id,
  pageId,
}: {
  id: string;
  pageId?: string;
}) => {
  return createSelector(selectBuilder, (builderState) => {
    let result = {
      mobile: true,
      desktop: true,
      current: true,
    };

    if (!(builderState.data && builderState.data.layout)) return result;
    if (!pageId) pageId = builderState.currentPageId;
    if (!pageId) return result;

    const currentContent = builderState.data.layout.contents.find(
      (x) => x.id === pageId,
    );
    if (!currentContent?.content) return result;

    for (const deviceType in currentContent.content) {
      const element = getObject(
        currentContent.content[deviceType as DeviceTypes],
        id,
      );
      if (element) {
        result = {
          ...result,
          [deviceType]: element.attributes?.style?.display !== 'none',
        };
      }
    }

    return {
      ...result,
      current: result?.[builderState.currentDeviceType || 'desktop'],
    };
  });
};

const makeSelectCampaignPageDetails = memoize((state: RootState) => {
  if (!(state.builder.data && state.builder.data.layout)) return null;
  if (!state.builder.data?.layout?.contents?.length) return null;

  return state.builder.data?.layout?.contents.map((content) => ({
    id: content.id,
    title: content.title,
    type: content.type,
    visibility: content.appearance?.visibility ?? true,
    content: content.content,
    appearance: content.appearance && content.appearance,
    template: content.template && content.template,
  }));
});

//TODO: define type
const makeSelectAllUsingFonts: any = createSelector(
  [(state: RootState) => state.builder.data?.layout],
  (layout) => {
    if (!layout) return [];
    const lastState: string[] = makeSelectAllUsingFonts.lastState || [];
    const result: string[] = uniq(getAllFonts(layout, []).fonts);
    if (isEqual(lastState, result)) return lastState;
    makeSelectAllUsingFonts.lastState = result;
    return result;
  },
);

const makeSelectAllUsingColors = createSelector(
  selectBuilder,
  (builderState) => {
    if (!(builderState.data && builderState.data.layout)) return [];
    const data: any = [];
    builderState.data.layout.contents.forEach((page) => {
      for (const deviceType in page.content) {
        data.push(page.content[deviceType as DeviceTypes]);
      }
    });

    return getAllColorsFromString(data);
  },
);

const makeSelectElementCountByNodeName = (nodeName: string) =>
  memoize((state: RootState) => {
    if (!(state.builder.data && state.builder.data.layout)) return 0;
    const { currentPageId, currentDeviceType } = state.builder;
    if (!currentDeviceType || !currentPageId) return 0;
    const currentNode = state.builder.data.layout.contents.find(
      (x) => x.id === currentPageId,
    )?.content[currentDeviceType];
    if (!currentNode) return 0;
    return getObjectCountByNodeName(currentNode, nodeName);
  });

const makeSelectCanvas = (pageId: string, deviceType: DeviceTypes) =>
  memoize((state: RootState) => {
    if (!(state.builder.data && state.builder.data.layout)) return null;
    if (!pageId || !deviceType) return 0;
    const currentNode = state.builder.data.layout.contents.find(
      (x) => x.id === pageId,
    )?.content[deviceType];
    if (!currentNode) return null;
    return currentNode;
  });

const makeSelectCustomizeSidebar = memoize((state: RootState) => {
  const builderState = state.builder;
  const deviceType = builderState.currentDeviceType;
  const pageId = builderState.currentPageId;

  if (!(builderState.data && builderState.data.layout)) return null;
  if (!builderState?.data?.layout?.contents?.length) return null;

  const currentContent = builderState.data.layout.contents?.find(
    (x) => x.id === pageId,
  );
  if (!currentContent?.content || !deviceType) return null;
  let result = {
    ...builderState.customizeSidebar,
  } as {
    id?: string;
    type: CustomizeSidebarTypes;
    node: Node;
    activeElementId?: string | undefined;
    addElementIndex?: number | undefined;
    secondaryElementId: string;
  };
  if (builderState.customizeSidebar.id) {
    result.node = getObject(
      currentContent.content[deviceType],
      builderState.customizeSidebar.id,
    );
  }
  function findFormNode(data: any) {
    for (const node of data) {
      if (node.nodeName === 'form') {
        return node;
      }
      if (node.childNodes && node.childNodes.length > 0) {
        const childResult: any = findFormNode(node.childNodes);
        if (childResult) {
          return childResult;
        }
      }
    }
    return null;
  }

  const formNode = findFormNode(currentContent?.content[deviceType].childNodes);

  const buttonWrapper = formNode?.childNodes.find(
    (node: any) => node.nodeName === 'button-wrapper',
  );
  const secondaryButton = buttonWrapper?.childNodes[1];
  if (
    secondaryButton &&
    secondaryButton.id === builderState.customizeSidebar.activeElementId
  ) {
    result = {
      ...builderState.customizeSidebar,
      activeElementId: buttonWrapper?.childNodes[0]!.id,
      node: getObject(
        currentContent.content[deviceType],
        builderState.customizeSidebar.id as string,
      ),
    } as any;
  }
  return result;
});

const makeSelectCustomizeSidebarWithoutNode = createSelector(
  selectBuilder,
  (builderState) => builderState.customizeSidebar,
);

const makeSelectCustomizeSidebarNode = memoize((state: RootState) => {
  const builderState = state.builder;
  const deviceType = builderState.currentDeviceType;
  const pageId = builderState.currentPageId;

  if (!(builderState.data && builderState.data.layout)) return null;
  if (!builderState?.data?.layout?.contents?.length) return null;

  const currentContent = builderState.data.layout.contents?.find(
    (x) => x.id === pageId,
  );
  if (!currentContent?.content || !deviceType) return null;
  const result = { ...builderState.customizeSidebar } as {
    id?: string;
    type: CustomizeSidebarTypes;
    node: Node;
  };
  if (builderState.customizeSidebar.id) {
    result.node = getObject(
      currentContent.content[deviceType],
      builderState.customizeSidebar.id,
    );
  }
  return result.node;
});

const makeSelectInlineEditor = createSelector(
  selectBuilder,
  (builderState) => builderState.inlineEditor,
);
const makeSelectCampaignCurrentStepAnimation = createSelector(
  selectBuilder,
  (builderState) => {
    if (!(builderState.data && builderState.data.layout)) return null;
    const deviceType = builderState.currentDeviceType;
    const pageId = builderState.currentPageId;
    if (!pageId || !deviceType) return;
    const currentContent = builderState.data.layout.contents.find(
      (x) => x.id === pageId,
    );
    if (!currentContent?.content) return null;
    return currentContent.appearance?.displayEffect.type;
  },
);
const makeSelectCampaignDomains = createSelector(
  selectBuilder,
  (builderState) => builderState.data?.domains,
);

const makeSelectOrjCampaignDomains = createSelector(
  selectBuilder,
  (builderState) => builderState?.orjData?.domains,
);

const makeSelectOrjCampaignTargets = createSelector(
  selectBuilder,
  (builderState) => builderState?.orjData?.targets,
);

const makeSelectActiveCustomizeSidebarType = createSelector(
  selectBuilder,
  (builderState) => builderState.customizeSidebar.type,
);

const makeSelectDataIsChanged = memoize((state: RootState) => {
  const { data, orjData } = state.builder;
  if (!(data && orjData)) return false;
  return !isEqual(data, orjData);
});
const makeSelectHoverId = createSelector(
  selectBuilder,
  (builderState) => builderState.hoverId,
);

const makeSelectActiveCustomizeSidebarId = createSelector(
  selectBuilder,
  (builderState) => builderState.customizeSidebar.id,
);

const makeSelectFirstPageId = createSelector(selectBuilder, (builderState) => {
  const firstPageId = builderState.data?.layout?.contents?.[0]?.id;
  if (!firstPageId) return null;

  return firstPageId;
});

const makeSelectCampaignCurrentPageType = createSelector(
  selectBuilder,
  (builderState) => {
    if (!builderState.currentPageId) return null;
    const currentPage = builderState.data?.layout?.contents.find(
      (x) => x.id === builderState.currentPageId,
    );
    return currentPage?.type;
  },
);

const makeSelectCampaignCurrentPageIsInline = createSelector(
  selectBuilder,
  (builderState) => {
    if (!builderState.currentPageId) return null;
    const currentPage = builderState.data?.layout?.contents.find(
      (x) => x.id === builderState.currentPageId,
    );
    return !!currentPage?.isInline;
  },
);

const makeSelectHighlighMode = createSelector(selectBuilder, (builderState) => {
  return builderState.highlight.mode;
});
const makeSelectTeaserPageOnActiveDevice = createSelector(
  selectBuilder,
  (builderState) =>
    builderState.data?.layout?.contents?.find(
      (x) => x.type === StepTypes.TEASER,
    ),
);
const makeSelectTeaserStatus = createSelector(
  selectBuilder,
  (builderState) =>
    builderState.data?.layout?.contents?.find(
      (x) => x.type === StepTypes.TEASER,
    )?.appearance.visibility,
);

const makeSelectAutomationEdit = createSelector(
  selectBuilder,
  (builderState) => builderState.emailAutomationEdit,
);

const makeSelectTotalCampaignCount = createSelector(
  selectBuilder,
  (builderState) => builderState.totalCampaignCount,
);

export {
  makeSelectCampaignId,
  makeSelectIsValidOrjLayout,
  makeSelectCampaignFetchStatus,
  makeSelectCampaignTargets,
  makeSelectCampaignTargetsCopy,
  makeSelectCampaignLayout,
  makeSelectOrjCampaignLayout,
  makeSelectActiveContent,
  makeSelectActiveContentType,
  makeSelectActiveContentElementByNodeName,
  makeSelectCampaignIntegration,
  makeSelectOrjCampaignDetail,
  makeSelectCampaignDetail,
  makeSelectPublishVersion,
  makeSelectCampaignSettings,
  makeSelectCampaignAutoResponder,
  makeSelectCampaignAutoResponderStatus,
  makeSelectCampaignCurrentDeviceType,
  makeSelectCampaignCurrentPageId,
  makeSelectCampaignCurrentPage,
  makeSelectCampaignCurrentOrjPage,
  makeSelectCampaignCurrentPageAppearance,
  makeSelectCampaignPreviousPageInfo as makeSelectCampaignPreviousPage,
  makeSelectCampaignNextPageInfo as makeSelectCampaignNextPage,
  makeSelectCampaignNextPageData,
  makeSelectCampaignVisitorDevice,
  makeSelectPreview,
  makeSelectCanvasDragDropStatus,
  makeSelectCampaignElement,
  makeSelectCampaignElementParent,
  makeSelectCampaignPageDetails,
  makeSelectElementVisibleInDevice,
  makeSelectAllUsingFonts,
  makeSelectAllUsingColors,
  makeSelectElementCountByNodeName,
  makeSelectCanvas,
  makeSelectCustomizeSidebar,
  makeSelectCustomizeSidebarNode,
  makeSelectInlineEditor,
  makeSelectCampaignCurrentStepAnimation,
  makeSelectCampaignDomains,
  makeSelectOrjCampaignDomains,
  makeSelectOrjCampaignTargets,
  makeSelectActiveCustomizeSidebarType,
  makeSelectDataIsChanged,
  makeSelectHoverId,
  makeSelectActiveCustomizeSidebarId,
  makeSelectFirstPageId,
  makeSelectCampaignCurrentPageTemplateImage,
  makeSelectCampaignCurrentPageTemplateId,
  makeSelectTargetsCopySegmentId,
  makeSelectCampaignCurrentPageType,
  makeSelectOrjActiveDeviceAllContents,
  makeSelectIsReadonlyCampaign,
  makeSelectHighlighMode,
  makeSelectCustomizeSidebarWithoutNode,
  makeSelectTeaserPageOnActiveDevice,
  makeSelectTeaserStatus,
  makeSelectCampaignCurrentPageIsInline,
  makeSelectFullyCommonAppearanceUsedPages,
  makeSelectAutomationEdit,
  makeSelectTotalCampaignCount,
};
