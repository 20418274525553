import { saveSettings } from '@connectors/builder/settings/actions';
import PSAccordion from '@elements/PSAccordion';
import Switch from '@elements/Switch';
import { cloneDeep } from 'lodash';
import { ChangeEvent } from 'react';
import { useDispatch } from 'react-redux';
import { FormSharingAccordionProps } from './types';
import PopupShareUrl from '@features/builder/settings/popup-share-url';
import { useAuth } from '@utils/useAuth';
import classNames from 'classnames';
import Loading from '@components/Loading';
import InfoIcon from '@assets/icons/info-outline.svg';
import { clearAllDrawer, showDrawer } from '@connectors/drawer/actions';
import LinkButton from '@components/LinkButton';
import { Trans, useTranslation } from 'react-i18next';

const FormSharingAccordion = ({
  id,
  isActive,
  onClickAccordion,
  campaign,
  className,
}: FormSharingAccordionProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { auth } = useAuth();

  const handleChangeStatus = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.checked;
    const settings = cloneDeep(campaign!.settings);
    if (!settings) return;
    if (value) settings.formSharing = value;
    else settings.formSharing = false;
    dispatch(saveSettings({ settings }));
  };

  const showHowItsWorkDrawer = (e: any, drawerKey: string) => {
    e.preventDefault();
    e.stopPropagation();
    if (!drawerKey) return null;

    dispatch(clearAllDrawer());
    dispatch(showDrawer({ id: drawerKey, header: 'How it works' }));
  };

  const renderAutoResponderSwitch = () => {
    return (
      <div className="p-[9px] flex gap-[10px] font-semibold text-xs leading-4 items-center">
        {t('sections:form-sharing-accordion:title')}
        <button
          className="how-its-work-drawer-button"
          onClick={(e) => {
            showHowItsWorkDrawer(e, 'FormSharing');
          }}
        >
          <InfoIcon
            className="text-gray-700 mr-[6px]"
            width="16"
            height="16"
            viewBox="0 0 24 24"
          />
        </button>
        <Switch
          className="inline-flex"
          elementSize="xs"
          onChange={handleChangeStatus}
          checked={campaign!.settings?.formSharing}
          disabled={campaign?.publishLoadingStatus === 'publishing'}
        />
      </div>
    );
  };

  return (
    <>
      <PSAccordion
        id={id}
        titleTemplate={renderAutoResponderSwitch()}
        isActive={isActive}
        size="sm"
        onClick={onClickAccordion}
        className={classNames(className, 'justify-center min-w-[48px]')}
      >
        <div className="my-[10px] mx-[10px] w-full">
          {campaign?.publishLoadingStatus === 'publishing' ? (
            <div className="flex items-center justify-center  min-h-[125px] relative">
              <Loading
                className="!absolute inset-0 !w-auto !h-auto bg-gray-50"
                backgroundClassName="!hidden"
                width={44}
                height={44}
                zIndexValue={0}
              />
            </div>
          ) : (
            <PopupShareUrl campaign={campaign!} accountId={auth.accountId} />
          )}
        </div>
      </PSAccordion>
    </>
  );
};

export default FormSharingAccordion;

export const FormSharingHowItsWork = () => {
  const { t } = useTranslation();
  return (
    <div className="p-5">
      <ol className=" flex flex-col gap-1.5 list-decimal ml-5">
        <li>
          <Trans i18nKey="sections:form-sharing-accordion:howItWorksListItemOne" />
        </li>
        <li>{t('sections:form-sharing-accordion:howItWorksListItemTwo')}</li>
        <li>{t('sections:form-sharing-accordion:howItWorksListItemThree')}</li>
        <li>
          <Trans i18nKey="sections:form-sharing-accordion:howItWorksListItemFour" />
        </li>
      </ol>
      <LinkButton
        className="mt-6"
        text={t('sections:form-sharing-accordion:documentationLinkText')}
        link="https://popupsmart.com/help/detail/how-to-share-your-popup-form-and-submission-with-a-link"
      />
    </div>
  );
};
