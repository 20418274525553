'use client';

import { FC } from 'react';
import dynamic from 'next/dynamic';
import cn from 'classnames';
import ErrorIcon from '@assets/icons/error-icon.svg';
import type { PSEditorProps } from './types';
import styles from './Editor.module.scss';

// Dynamically import TiptapEditor with SSR disabled
const TiptapEditor = dynamic(() => import('@elements/TiptapEditor'), {
  ssr: false,
});

const PSEditor: FC<PSEditorProps> = ({
  label,
  field: { value, name, onChange },
  fieldState: { error },
  disabled,
  onChangeEvent,
  height = 300,
  hiddenToolbar = false,
  checkChangesIsValidRegexp,
  className,
  /* showHtmlEditorDialog = true, */
}) => {
  const isValueValid = (checkValue: string) => {
    if (!checkChangesIsValidRegexp) return true;
    const regex = new RegExp(checkChangesIsValidRegexp);
    return regex.test(checkValue);
  };

  const handleChange = (html: string) => {
    if (!isValueValid(html)) return;
    onChange(html);
    onChangeEvent?.(html);
  };

  return (
    <div className={cn(styles.container, className)}>
      <div
        className={cn({
          [styles.inputWrapper]: true,
          [styles.inputWrapperRelative]: disabled,
        })}
      >
        {label && (
          <label className={styles.label} htmlFor={name}>
            {label}
          </label>
        )}
        <TiptapEditor
          id={name}
          value={value}
          onChange={handleChange}
          onlyEditor={hiddenToolbar}
          editorStyle={{ minHeight: height }}
          className={cn(styles.editor, { [styles.disabled]: disabled })}
          isAlignDisabled={false}
          smartTagButtonSize="md"
          elementSize="md"
          withUnsubscribe={true}
          style={{
            fontSize: '14px',
            lineHeight: '24px',
          }}
        />
        {error && (
          <div className={styles.error}>
            <ErrorIcon />
            <span>{error.message}</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default PSEditor;
