import { ReduxAction } from 'global';
import store from '@utils/store';

import {
  LOGIN_REQUEST,
  LOGIN_FAILURE,
  REGISTER_REQUEST,
  REGISTER_FAILURE,
  FORGOT_PASSWORD_REQUEST,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_FAILURE,
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAILURE,
  CHANGE_PASSWORD_REQUEST,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_FAILURE,
  REGISTER_WITH_INVITE_REQUEST,
  REGISTER_WITH_INVITE_FAILURE,
  LOGIN_WITH_GOOGLE_REQUEST,
  ADD_TOKEN_MANUALLY_REQUEST,
  ADD_TOKEN_MANUALLY_FAILURE,
  GET_AUTH_USER,
  GET_AUTH_USER_SUCCESS,
  GET_AUTH_USER_FAILURE,
  SET_AUTH_USER,
  GET_USER_REQUEST,
  GET_USER_FAILURE,
  EDIT_USER_REQUEST,
  EDIT_USER_FAILURE,
  EDIT_EMAIL_REQUEST,
  EDIT_EMAIL_SUCCESS,
  EDIT_EMAIL_FAILURE,
  DELETE_USER_REQUEST,
  DELETE_USER_FAILURE,
  DELETE_SURVEY_REQUEST,
  DELETE_SURVEY_FAILURE,
  USER_LOGOUT,
  DELETE_SURVEY_SUCCESS,
  CHANGE_SUBACCOUNT_SEEN_REQUEST,
  CHANGE_SUBACCOUNT_SEEN_SUCCESS,
  CHANGE_SUBACCOUNT_SEEN_FAILURE,
  SET_AUTH_USER_APOLLO,
} from './constants';
import { RESET_STORE } from '@connectors/actions';
import produce, { Draft } from 'immer';
import { meData } from './types';

export interface User {
  id?: number;
  userName?: string;
  userSurname?: string;
  userBirthDate?: Date;
  userEmail?: string;
  userPhone?: number | string;
  userStatus?: boolean;
  apiToken?: string;
  userSelectedLanguageCode?: null;
  userType?: string;
  userIsShopifyUser?: false;
  userEmailVerify?: false;
  accountId?: number;
  accountStripeId?: string;
  accountCurrentMonthlyPageViewCount?: number;
  accountIsGettingStartedCompleted?: boolean;
  integryHash?: string;
  integryToken?: string;
  countryUser?: any;
  userPermission?: any;
  accountPayments?: any;
  accountPlan?: {
    id: number;
    name: string;
    description: string;
    type: string;
    stripeId: string;
    popupCount: number;
    interactionCount: number;
    impressionCount: number;
    domainCount: number;
    displayCount: number;
    price: number;
  };
  websites?: any;
  status?: string;
  loginStatus?: string;
  authStatus: string;
  isOwner?: boolean;
  token?: string;
  errorType?: string;
  meData?: meData;
}

const initialState: User = {
  status: 'initialized',
  authStatus: 'initialized',
  errorType: undefined,
  meData: {
    id: 0,
    name: '',
    surname: '',
    accountId: '',
    accountIsGettingStartedCompleted: false,
    accountUseProxiedFonts: false,
    apiToken: '',
    email: '',
    emailVerify: false,
    phone_number: '',
    isOwner: false,
    isShopifyUser: false,
    isPageViewBlocked: false,
    accountPlan: {
      description: '',
      displayCount: 0,
      domainCount: 0,
      id: 0,
      impressionCount: 0,
      interactionCount: 0,
      name: '',
      popupCount: 0,
      price: 0,
      stripeId: '',
      type: '',
      activePopupCount: 0,
    },
    isMigrated: false,
    paymentProvider: 'STRIPE',
    baseShopifyStore: '',
    __typename: 'User',
    isShopify: false,
    userHasShopifyWebsite: false,
    systemLanguage: '',
    isAgency: false,
    isSubWorkspace: false,
    accountName: '',
    logoUrl: '',
  },
};

const reducer = produce((draft: Draft<User>, action: ReduxAction) => {
  const { type, payload } = action;
  switch (type) {
    case LOGIN_REQUEST:
    case REGISTER_REQUEST:
    case FORGOT_PASSWORD_REQUEST:
    case RESET_PASSWORD_REQUEST:
    case CHANGE_PASSWORD_REQUEST:
    case REGISTER_WITH_INVITE_REQUEST:
    case LOGIN_WITH_GOOGLE_REQUEST:
    case GET_AUTH_USER:
    case DELETE_SURVEY_REQUEST:
    case ADD_TOKEN_MANUALLY_REQUEST:
    case GET_USER_REQUEST:
    case EDIT_USER_REQUEST:
    case DELETE_USER_REQUEST:
    case CHANGE_SUBACCOUNT_SEEN_REQUEST:
      draft.status = 'loading';
      return draft;
    case SET_AUTH_USER: {
      store.set('user', action.payload);
      draft.loginStatus = 'success';
      return draft;
    }
    case SET_AUTH_USER_APOLLO: {
      draft.meData = payload;
      draft.authStatus = 'success';
      return draft;
    }
    case FORGOT_PASSWORD_SUCCESS:
      // //Don't return a success status because it breaks the login page
      draft = initialState;
      return draft;
    case RESET_PASSWORD_SUCCESS:
      draft.status = 'success';
      return draft;
    case CHANGE_PASSWORD_SUCCESS:
      draft.status = 'success';
      return draft;
    case LOGIN_FAILURE:
      draft.status = 'errored';
      return draft;
    case REGISTER_FAILURE:
      draft.status = 'errored';
      draft.errorType = action.payload;
      return draft;

    case FORGOT_PASSWORD_FAILURE:
      draft.status = 'errored';
      return draft;
    case RESET_PASSWORD_FAILURE:
      draft.status = 'errored';
      return draft;
    case CHANGE_PASSWORD_FAILURE:
      draft.status = 'errored';
      return draft;

    case REGISTER_WITH_INVITE_FAILURE:
      draft.status = 'errored';
      return draft;

    case DELETE_SURVEY_SUCCESS:
      draft.status = 'success';
      return draft;

    case GET_AUTH_USER_SUCCESS:
      draft = { ...draft, ...payload };
      draft.status = 'success';
      return draft;
    case EDIT_USER_FAILURE:
      draft.status = 'errored';
      return draft;

    case GET_USER_FAILURE:
      draft.status = 'errored';
      return draft;
    case DELETE_USER_FAILURE:
      draft.status = 'errored';
      return draft;

    case DELETE_SURVEY_FAILURE:
      draft.status = 'errored';
      return draft;
    case ADD_TOKEN_MANUALLY_FAILURE:
      draft.status = 'errored';
      return draft;
    case GET_AUTH_USER_FAILURE:
      draft.status = 'errored';
      return draft;
    case USER_LOGOUT: {
      const editMobileModalDisabled = store.get('editMobileModalDisabled');
      //Clear the store, and re-set editMobileModal if it's true
      store.clearTemps();
      if (editMobileModalDisabled) {
        store.set('editMobileModalDisabled', editMobileModalDisabled);
      }
      draft = initialState;
      return draft;
    }
    case EDIT_EMAIL_REQUEST:
      draft.status = 'loading';
      return draft;
    case EDIT_EMAIL_SUCCESS:
      draft.status = 'success';
      return draft;
    case EDIT_EMAIL_FAILURE:
      draft.status = 'errored';
      return draft;
    case CHANGE_SUBACCOUNT_SEEN_SUCCESS:
      store.set('getting-started-completed', true);
      draft.accountIsGettingStartedCompleted = true;
      draft.status = 'success';
      return draft;

    case CHANGE_SUBACCOUNT_SEEN_FAILURE:
      draft.status = 'errored';
      return draft;
    case RESET_STORE:
      draft = initialState;
      return draft;
    default:
      return draft;
  }
}, initialState);

export default reducer;
