import type { TypeSelectProps } from './types';

import React, { FC } from 'react';
import cn from 'classnames';

import styles from './TypeSelect.module.scss';
import { Tooltip } from 'ui';

const TypeSelect: FC<TypeSelectProps> = ({
  className,
  value,
  options,
  onChange,
}) => {
  const handleChange = (value: string) => {
    onChange && onChange(value);
  };

  return (
    <div className={cn(styles.wrapper, className)} data-testid="type-select">
      {options && !!options?.length
        ? options.map((option, index) => (
            <Tooltip
              keepTooltip
              className="!flex !w-full"
              style={{ top: '-42%', whiteSpace: 'normal', width: '85%' }}
              content={option.tooltip ? option.tooltip : ''}
              key={option.value}
            >
              <button
                key={option.value}
                className={cn(styles.typeButtons, {
                  [styles.active]: value === option.value,
                })}
                onClick={() => handleChange(option.value)}
                data-testid={`type-select-button-${option.value}`}
              >
                <span
                  className={cn(styles.buttonContent, {
                    [styles.first]: index === 0,
                    [styles.last]: index === options.length - 1,
                    'flex-col': option.name,
                  })}
                >
                  {option.icon}
                  {value === option.value && option.label !== '' && (
                    <p
                      className={styles.label}
                      data-testid={`type-select-label`}
                    >
                      {option.label}
                    </p>
                  )}
                  {option.name && (
                    <p
                      className={'text-[10px]'}
                      // data-testid={`type-select-label`}
                    >
                      {option.name}
                    </p>
                  )}
                </span>
              </button>
            </Tooltip>
          ))
        : null}
    </div>
  );
};

export default TypeSelect;
